// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

import styles from './Email.module.scss';

const HeaderEmail = ({ className }) => {
  return (
    <div className={cx(styles.header, className)}>
      <Helmet
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      <img
        className={styles.logo}
        src={require('../../images/logo-white.svg')}
        alt="Paidy Logo"
        height="29"
        loading="lazy"
        width="100"
      />
    </div>
  );
};

export default HeaderEmail;
