// @flow
import React from 'react';

import Layout from '../../../../components/Layout/Layout';
import HeaderEmail from '../../../../components/Email/HeaderEmail';
import FooterEmail from '../../../../components/Email/FooterEmail';

import styles from '../unsubscribe.module.scss';
import useNavigateBackToTop from '../../../../hooks/useNavigateBackToTop';

const UnsubscribeThankYou = () => {
  useNavigateBackToTop();

  return (
    <Layout hasNoHeader hasNoFooter>
      <div className={styles.wrapper}>
        <HeaderEmail className={styles.header} />
        <div className={styles.content}>
          <img
            className={styles.headImg}
            src={require('../../../../images/icon-value-you.svg')}
            height="118px"
            width="166px"
            alt="Thank you icon"
            loading="lazy"
          />
          <br />
          <h2 className={styles.heading}>
            貴重なご意見、
            <br />
            ありがとうございました
          </h2>
          <p className={styles.p}>
            貴重なご意見をお寄せいただき、ありがとうございます。今後のサービス向上に活かしてまいります。
          </p>
        </div>
        <FooterEmail className={styles.footer} />
      </div>
    </Layout>
  );
};

export default UnsubscribeThankYou;
