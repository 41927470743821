// @flow
import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Email.module.scss';

const FooterEmail = ({ className }) => {
  return (
    <div className={cx(styles.footer, className)}>
      <Link className={styles.link} to="/">
        www.paidy.com
      </Link>
      <br />
      <small>&copy; Paidy Inc.</small>
      <br />
      <OutboundLink
        className={styles.icon}
        href="https://twitter.com/paidyjp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require('../../images/icon-x-black.svg')}
          alt="Paidy | Twitter"
          width="26"
          height="26"
          loading="lazy"
        />
      </OutboundLink>
    </div>
  );
};

export default FooterEmail;
